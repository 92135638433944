.ui.container.admin-page {
  background-color: white;
  left: 5%;
  padding: 0 2rem;
  position: relative;
  width: 90%;
}

.ui.input.admin-input {
  width: 300px;
}

.admin-title {
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0.5rem;
}
