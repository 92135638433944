.ui.container.platform-list-page {
  background-color: white;
  left: 5%;
  padding: 0 2rem;
  position: relative;
  width: 90%;
}

.platform-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 20px;
  color: #46258d;
}

.platform-division {
  border-bottom: 2px solid #6435c9;
  padding-bottom: 15px;
  border-radius: 5px;
}
