.lang-selector {
  margin-right: 10px;
}

.icon-lang {
  cursor: pointer;
  margin-right: 0.4rem;
  margin-top: 0.3rem;
  padding: 1px;
}
.icon-lang.active {
  border: 3px solid black
}
