.ui.form .no-resize textarea {
  resize: none;
}
.bg-danger {
  background-color: #db2828 !important;
  color: white !important;
}

/* padding */
/* start */
.ps-0, .px-0, .p-0 {
  padding-left: 0 !important;
}
.ps-1, .px-1, .p-1 {
  padding-left: 0.375rem !important;
}
.ps-2, .px-2, .p-2 {
  padding-left: 0.75rem !important;
}
.ps-3, .px-3, .p-3 {
  padding-left: 1.5rem !important;
}
.ps-4, .px-4, .p-4 {
  padding-left: 2.25rem !important;
}
.ps-5, .px-5, .p-5 {
  padding-left: 4.5rem !important;
}
/* end */
.pe-0, .px-0, .p-0 {
  padding-right: 0 !important;
}
.pe-1, .px-1, .p-1 {
  padding-right: 0.375rem !important;
}
.pe-2, .px-2, .p-2 {
  padding-right: 0.75rem !important;
}
.pe-3, .px-3, .p-3 {
  padding-right: 1.5rem !important;
}
.pe-4, .px-4, .p-4 {
  padding-right: 2.25rem !important;
}
.pe-5, .px-5, .p-5 {
  padding-right: 4.5rem !important;
}
/* top */
.pt-0, .py-0, .p-0 {
  padding-top: 0 !important;
}
.pt-1, .py-1, .p-1 {
  padding-top: 0.375rem !important;
}
.pt-2, .py-2, .p-2 {
  padding-top: 0.75rem !important;
}
.pt-3, .py-3, .p-3 {
  padding-top: 1.5rem !important;
}
.pt-4, .py-4, .p-4 {
  padding-top: 2.25rem !important;
}
.pt-5, .py-5, .p-5 {
  padding-top: 4.5rem !important;
}
/* bottom */
.pb-0, .py-0, .p-0 {
  padding-bottom: 0 !important;
}
.pb-1, .py-1, .p-1 {
  padding-bottom: 0.375rem !important;
}
.pb-2, .py-2, .p-2 {
  padding-bottom: 0.75rem !important;
}
.pb-3, .py-3, .p-3 {
  padding-bottom: 1.5rem !important;
}
.pb-4, .py-4, .p-4 {
  padding-bottom: 2.25rem !important;
}
.pb-5, .py-5, .p-5 {
  padding-bottom: 4.5rem !important;
}

/* margin */
/* start */
.ms-0, .mx-0, .m-0 {
  margin-left: 0 !important;
}
.ms-1, .mx-1, .m-1 {
  margin-left: 0.375rem !important;
}
.ms-2, .mx-2, .m-2 {
  margin-left: 0.75rem !important;
}
.ms-3, .mx-3, .m-3 {
  margin-left: 1.5rem !important;
}
.ms-4, .mx-4, .m-4 {
  margin-left: 2.25rem !important;
}
.ms-5, .mx-5, .m-5 {
  margin-left: 4.5rem !important;
}
/* end */
.me-0, .mx-0, .m-0 {
  margin-right: 0 !important;
}
.me-1, .mx-1, .m-1 {
  margin-right: 0.375rem !important;
}
.me-2, .mx-2, .m-2 {
  margin-right: 0.75rem !important;
}
.me-3, .mx-3, .m-3 {
  margin-right: 1.5rem !important;
}
.me-4, .mx-4, .m-4 {
  margin-right: 2.25rem !important;
}
.me-5, .mx-5, .m-5 {
  margin-right: 4.5rem !important;
}
/* top */
.mt-0, .my-0, .m-0 {
  margin-top: 0 !important;
}
.mt-1, .my-1, .m-1 {
  margin-top: 0.375rem !important;
}
.mt-2, .my-2, .m-2 {
  margin-top: 0.75rem !important;
}
.mt-3, .my-3, .m-3 {
  margin-top: 1.5rem !important;
}
.mt-4, .my-4, .m-4 {
  margin-top: 2.25rem !important;
}
.mt-5, .my-5, .m-5 {
  margin-top: 4.5rem !important;
}
/* bottom */
.mb-0, .my-0, .m-0 {
  margin-bottom: 0 !important;
}
.mb-1, .my-1, .m-1 {
  margin-bottom: 0.375rem !important;
}
.mb-2, .my-2, .m-2 {
  margin-bottom: 0.75rem !important;
}
.mb-3, .my-3, .m-3 {
  margin-bottom: 1.5rem !important;
}
.mb-4, .my-4, .m-4 {
  margin-bottom: 2.25rem !important;
}
.mb-5, .my-5, .m-5 {
  margin-bottom: 4.5rem !important;
}
