.ui.container.game-list-page {
  background-color: white;
  left: 5%;
  padding: 0 2rem;
  position: relative;
  width: 90%;
}

.game-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 20px;
  color: #175c92;
}

.game-division {
  border-bottom: 2px solid #175c92;
  padding-bottom: 15px;
  border-radius: 5px;
}

