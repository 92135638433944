.ui.container.not-found-page {
  border-radius: .5rem;
  left: 5%;
  margin: 2rem 1rem;
  padding: 10rem 1rem;
  position: relative;
  width: 80%;
  background-color: white;
  text-align: center;
  vertical-align: middle;
}
