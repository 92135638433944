.ui.container.serie-list-page {
  background-color: white;
  left: 5%;
  padding: 0 2rem;
  position: relative;
  width: 90%;
}

.serie-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 20px;
  color: #74258e;
}

.serie-division {
  border-bottom: 2px solid #a333c8;
  padding-bottom: 15px;
  border-radius: 5px;
}

