.ui.container.esrb-list-page {
  background-color: white;
  left: 5%;
  padding: 0 2rem;
  position: relative;
  width: 90%;
}

.esrb-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 20px;
  color: #b08503;
}

.esrb-division {
  border-bottom: 2px solid #b08503;
  padding-bottom: 15px;
  border-radius: 5px;
}

