.guide-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #991a1a;
}

.guide-division {
  border-bottom: 2px solid #a5673f;
  border-radius: 5px;
  padding-bottom: 15px;
}

.guide-container {
  display: flex;
  margin-top: 10px;
}

.guide-page-container {
  display: column;
  margin: 1rem;
  width: 50%;
}

.guide-page-title {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  padding: 1rem;
}

.guide-button {
  margin-left: 5px;
}

.guide-container-flex {
  align-items: center;
  display: flex;
}

.guide-button {
  margin-left: 10px;
}

.guide-input {
  padding: .5em;
  width: 100%;
}

.guide-list {
  list-style-type: none;
}

.guide-list-items {
  font-size: 18px;
  padding: 0.2rem
}

.guide-list-anchors {
  color: #4183c4;
  cursor: pointer;
  margin-left: 0.5rem;
}