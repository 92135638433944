.ui.dropdown.user {
  font-size: 1.8em;
}

.ui.dropdown > div.menu-position {
  right: 0;
  left: auto;
}

.ui.dropdown > div.menu-position > div.item.menu-name {
  color: black;
  opacity: 1;
  font-weight: bold;
}

.ui.dropdown > div.menu-position > div.item.menu-email {
  color: #106bb3;
  opacity: 1;
}
