.ui.container.home-page {
  background-color: white;
  left: 5%;
  position: relative;
  width: 90%;
}

.home-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin: 20px;
}

.home-menu {
  background-color: white;
  align-items: center;
  border-radius: .5rem;
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  cursor: pointer;
}

.home-menu:hover {
  filter: brightness(90%);
}

.home-text {
  font-size: 1.2rem;
  margin-top: 1rem;
}
