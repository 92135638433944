.ui.header.layout-header {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 1;
  padding-bottom: 2px;
  padding-top: 6px;
}

.header-title {
  margin-top: 5px;
  margin-right: 0.5rem;
  margin-bottom: 10px;
}

.header-home {
  margin-top: 5px;
  margin-left: 0.5rem;
  margin-bottom: 10px;
}

.header-arrow {
  margin-top: 8px;
  margin-bottom: 0;
}

.ui.header a {
  color: #4183c4;
}

.header-options {
  width: 100%;
  max-height: 35px;
}
.header-options div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
