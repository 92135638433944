.date-picker-container {
  display: flex;
  flex-direction: column;
  margin: .5rem;
  width: 100%;
}

.date-picker-container span {
  font-size: .92857143em;
  font-weight: bold;
}

.date-picker {
  width: 100%;
}