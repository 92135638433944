.ui.menu.aside-menu {
  height: 100%;
  position: fixed;
  width: 10%;
}

.ui.menu.aside-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.1rem;
  cursor: pointer;
}

.ui.menu.aside-item:hover {
  filter: brightness(90%);
}

.menu-text {
  margin-top: .5rem;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.4285em;
}
