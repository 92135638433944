.platform-game-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #175c92;
  display: flex;
  justify-content: space-between;
}

.platform-game-division {
  border-bottom: 2px solid #175c92;
  padding-bottom: 15px;
}

.platform-game-container-empty {
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  padding-top: 1.5rem;
}

.ui.container.platform-game-container {
  border-radius: .5rem;
  left: 5%;
  margin: 2rem 1rem;
  padding: 1rem;
  position: relative;
  width: 80%;
  background-color: white;
}